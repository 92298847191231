'use strict';

var mailTemplateModule = angular.module('t2g.mailTemplate', [
  'gettext',
  'ui.router',
  require('t2g.common.services.notificationService').name
]);

module.exports = mailTemplateModule;

mailTemplateModule.controller('MailTemplateCtrl', require('./MailTemplateCtrl'));

mailTemplateModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.mailTemplate', {
      url: '/mail-template/:templateName',
      title: 'E-Mail Templates',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/mailTemplate/MailTemplateView.html?v=3.84.1',
      controller: 'MailTemplateCtrl as MailTemplateCtrl',
      resolve: {
        template: function ($stateParams,
                            MailDA) {
          return MailDA.get($stateParams.templateName);
        },
        placeholder: function ($stateParams,
                               placeholderSettings) {
          return placeholderSettings[$stateParams.templateName];
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    });
});

