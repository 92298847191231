'use strict';

var resourcesModule = angular.module('t2g.resources', [
    'gettext',
    'ui.router',
    require('t2g.common.data-access.sync').name,
    require('t2g.common.services.notificationService').name
]);

module.exports = resourcesModule;

resourcesModule.controller('ResourcesEmployeeDetailsCtrl', require('./ResourcesEmployeeDetailsCtrl'));
resourcesModule.controller('ResourcesListCtrl', require('./ResourcesListCtrl'));
resourcesModule.controller('ResourcesRoomDetailsCtrl', require('./ResourcesRoomDetailsCtrl'));

var officeResolve = function (OfficeDA) {
    return OfficeDA.get();
};

var resourceResolve = function (ResourceDA) {
    return ResourceDA
      .list()
      .then(function (response) {
          return response.resources;
      });
};

var resourceDetailResolve = function ($q, $stateParams, ResourceDA) {
    var deffered = $q.defer();

    if (!$stateParams.resourceId) {

        var resource = {
            workingHours: [],
            absent: [],
            bookable: true
        };

        for (var i = 0; i < 5; i++) {
            resource.workingHours.push({
                day: i,
                available: true,
                times: [{start: 540, end: 1020}]
            });
        }

        resource.workingHours.push({
            day: 5,
            available: false,
            times: [
                {
                    start: 540,
                    end: 720
                }
            ]
        });

        resource.workingHours.push({
            day: 6,
            available: false,
            times: [
                {
                    start: 540,
                    end: 720
                }
            ]
        });

        deffered.resolve(resource);
    } else {
        ResourceDA
          .get($stateParams.resourceId)
          .then((response) => {
              if (angular.isArray(response.resource.absent)) {
                  angular.forEach(response.resource.absent, function (absence) {
                      absence.start = new Date(absence.start);
                      absence.end = new Date(absence.end);
                  });
              }

              deffered.resolve(response.resource);
          })
          .catch((error) => {
            deffered.reject(error);
          });
    }

    return deffered.promise;
};


resourcesModule.config(function ($stateProvider) {
    $stateProvider
      .state('private.main.resources', {
          url: '/resources',
          title: 'Employees',
          templateUrl: 'https://cdn.termin2go.com/app/app/modules/resources/ResourceListView.html?v=3.84.1',
          controller: 'ResourcesListCtrl as ResourcesListCtrl',
          resolve: {
              resources: resourceResolve
          }
      })
      .state('private.main.resourcesDetails', {
          url: '/resources/:type/:resourceId',
          title: 'Employees',
          templateUrl: function ($stateParams) {
              var type;

              if ($stateParams.type === 'room') {
                  type = 'Room';
              } else {
                  type = 'Employee';
              }

              return 'https://cdn.termin2go.com/app/app/modules/resources/Resource' + type + 'DetailsView.html?v=3.84.1';
          },
          controllerProvider: function ($stateParams) {
              var controllerName;

              if ($stateParams.type === 'room') {
                  controllerName = 'ResourcesRoomDetailsCtrl';
              } else {
                  controllerName = 'ResourcesEmployeeDetailsCtrl';
              }

              return controllerName;
          },
          resolve: {
              officeResolve: officeResolve,
              resourceResolve: resourceDetailResolve
          }
      })
    ;
});




