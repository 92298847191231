function UserModalCtrl($rootScope, user, userSession, UserDA, gettextCatalog, Notification, employees, modalInstance) {
  this._modalInstance = modalInstance;
  this._gettextCatalog = gettextCatalog;
  this._Notification = Notification;
  this._UserDA = UserDA;
  this.user = user;
  this.employees = employees;
  this.inputType = 'password';
  this.showPassword = false;
  this.branches = userSession.branchNames;
  this.apiKey = null;

  this.accessTypes = {
    appointent: this.user.rights.appointments.edit === 'all' ? 'all' : 'personal',
    workingHours: this.user.rights.workingHours.edit === 'all' ? 'all' : 'personal',
  };

  $rootScope.$watch(() => this.accessTypes.appointent, (newAccessTypes, oldAccessTypes) => {
    if (oldAccessTypes === 'all') {
      this.user.rights.appointments.edit = this.employees[0]._id;
    }
  });

  $rootScope.$watch(() => this.accessTypes.workingHours, (newAccessTypes, oldAccessTypes) => {
    if (oldAccessTypes === 'all') {
      this.user.rights.workingHours.edit = this.employees[0]._id;
    }
  });
}

UserModalCtrl.prototype.close = function () {
  this._modalInstance.close();
};

UserModalCtrl.prototype.abort = function () {
  this.close();
};

UserModalCtrl.prototype.changeInputType = function () {
  if (this.showPassword) {
    this.inputType = 'text';
  } else {
    this.inputType = 'password';
  }
};

UserModalCtrl.prototype.remove = function () {
  this._UserDA
    .remove(this.user._id)
    .then(() => {
      this._Notification.addSuccessMessage(this._gettextCatalog.getString('User deleted'));
      this.close();
    })
    .catch(() => {
      this._Notification.addErrorMessage(this._gettextCatalog.getString('Cant delete user'));
    });
};

UserModalCtrl.prototype.save = function () {
  if (this.accessTypes.appointent === 'all') {
    this.user.rights.appointments.edit = 'all';
  }

  if (this.accessTypes.workingHours === 'all') {
    this.user.rights.workingHours.edit = 'all';
  }

  if (!this.user._id) {
    this._UserDA
      .insert(this.user)
      .then(() => {
        this._Notification.addSuccessMessage(this._gettextCatalog.getString('User added'));
        this.close();
      })
      .catch((error) => {
        this._Notification.addErrorMessage({ text: error && error.data ? error.data.error : '' });
      });
  } else {
    this._UserDA
      .update(this.user)
      .then(() => {
        this._Notification.addSuccessMessage(this._gettextCatalog.getString('User edited'));
        this.close();
      })
      .catch((error) => {
        this._Notification.addErrorMessage({ text: error && error.data ? error.data.error : '' });
      });
  }
};

UserModalCtrl.prototype.toggleBranchSelection = function (branchId) {
  const index = this.user.branches.indexOf(branchId);

  if (index > -1) {
    this.user.branches.splice(index, 1);
  } else {
    this.user.branches.push(branchId);
  }
};

UserModalCtrl.prototype.createNewApiKey = function () {
  this._UserDA
    .generateAPIKey(this.user)
    .then((apiKey) => {
      this._Notification.addSuccessMessage(this._gettextCatalog.getString('New API Key generated'));
      this.apiKey = apiKey;
    })
    .catch((error) => {
      this._Notification.addErrorMessage({ text: error && error.data ? error.data.error : '' });
    });
};

module.exports = UserModalCtrl;
