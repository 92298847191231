var CalendarContainerCtrl = function (calendar,
                                      ModalService,
                                      resources,
                                      services,
                                      office) {
    this.calendarModel = calendar;
    this.resources = resources;
    this.services = services;
    this.office = office;
    this._ModalService = ModalService;
    if(!calendar || !calendar.length || !calendar[0].resources || !calendar[0].resources.length) {
        this.noResourced = true;
    }
};

CalendarContainerCtrl.prototype.addAppointment = function() {
    const roundedUp = Math.ceil(moment().minute() / 5) * 5;
    const start = moment().minute(roundedUp).second(0).toDate();
    const appointment = {
        start: start,
        end: moment(start).add(30, 'minutes').toDate()
    };

    this._ModalService.openAppointmentModal(appointment);
};

module.exports = CalendarContainerCtrl;
