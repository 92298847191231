'use strict';

var WeeklyCtrl = function ($scope,
                                 $state,
                                 $stateParams,
                                 employee,
                                 gettextCatalog,
                                 office,
                                 services,
                                 Notification,
                                 moment,
                                 ResourceDA) {
  this.gettextCatalog = gettextCatalog;
  this._Notification = Notification;
  this._ResourceDA = ResourceDA;
  this._state = $state;
  this._stateParams = $stateParams;
  this._moment = moment;

  this.employee = employee;
  this.weekDays = [];
  this.office = office;
  this.isSecondTimeWeekly = this.checkSecondTimeWeekly();
  this.serviceWorkingHourIndex = -1;
  this.weekValidation = [
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1}
  ];
  this.serviceName = '';

  $scope.$on('t2g:shift-planning:save:clicked', () => {
    this.saveWeekly();
  });

  if ($stateParams.serviceId) {
    this.serviceWorkingHourIndex = employee.serviceWorkingHours.findIndex((serviceWorkingHour) => serviceWorkingHour.serviceId === $stateParams.serviceId);
    this.workingHoursRef = employee.serviceWorkingHours[this.serviceWorkingHourIndex].hours;
    this.serviceName = services[$stateParams.serviceId].label;
  } else {
    this.workingHoursRef = employee.workingHours;
  }
};

/**
 * copy opening hours to working hours
 */
WeeklyCtrl.prototype.applyOpeningHours = function () {
  var self = this;

  _.forEach(this.office.openingHours.regular, function (openingHour, index) {
    var workingHour = self.workingHoursRef[index];
    workingHour.available = openingHour.open;
    workingHour.times = openingHour.times;
  });

  this.isSecondTimeWeekly = this.checkSecondTimeWeekly();
};

/**
 * save weekly working hours (shifts)
 */
WeeklyCtrl.prototype.saveWeekly = function () {
  var self = this;

  let valid = true;
  this.weekValidation = [
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1},
    {valid: true, invalidTimeIndex: -1}
  ];

  // validate input
  this.workingHoursRef.forEach((day, dayIndex) => {
    if (day.available && day.times) {
      day.times.forEach((time, timeIndex) => {
        if (time.end <= time.start) {
          this.weekValidation[dayIndex].valid = false;
          this.weekValidation[dayIndex].invalidTimeIndex = timeIndex;
          valid = false;
        }

        if (timeIndex > 0) {
          if (time.start <= day.times[timeIndex - 1].end) {
            this.weekValidation[dayIndex].valid = false;
            this.weekValidation[dayIndex].invalidTimeIndex = timeIndex;
            valid = false;
          }
        }
      });
    }
  });

  if(!valid) {
    this._Notification.addErrorMessage(self.gettextCatalog.getString('Please check the marked times. Start and End time are overlapping'));
  } else {
    let update = {_id: this.employee._id};

    if (this._stateParams.serviceId) {
      update.serviceWorkingHours = this.employee.serviceWorkingHours;
    } else {
      update.workingHours = this.workingHoursRef;
    }

    this._ResourceDA.update(update)
        .then(
            function () {
              self._Notification.addSuccessMessage(self.gettextCatalog.getString('Working hours saved'));
            },
            function (error) {
              self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant save working hours (Error: {{error}})', {error: error}));
            }
        );
  }
};

/**
 * helper function for ng-repeat (repeat x times)
 * @param {Number} num
 * @returns {Array}
 */
WeeklyCtrl.prototype.getNumber = function (num) {
  return new Array(num);
};


/**
 * check if a second time was added in the weekly planning
 * @returns {boolean}
 */
WeeklyCtrl.prototype.checkSecondTimeWeekly = function () {
  var secondTimeIndex = _.findIndex(this.workingHoursRef, function (workingHours) {
    return workingHours.times.length > 1;
  });

  return secondTimeIndex > -1 ? true : false;
};

/**
 * shorthand for availability of a weekly planning day
 * @param {Number} index
 */
WeeklyCtrl.prototype.isAvailableWH = function (index) {
  var workingHours = this.workingHoursRef[index];
  return workingHours ? workingHours.available : false;
};


/**
 * add weekly secondary time
 * @param {Number} index
 */
WeeklyCtrl.prototype.addWeeklyTime = function (dayIndex) {
  var endTimeBefore = this.workingHoursRef[dayIndex].times[this.workingHoursRef[dayIndex].times.length - 1].end;

  this.workingHoursRef[dayIndex].times.push({
    start: ((endTimeBefore + 60) > 1440) ? 1440 : endTimeBefore + 60,
    end: ((endTimeBefore + 240) > 1440) ? 1440 : endTimeBefore + 240
  });

  this.isSecondTimeWeekly = true;
};


/**
 * remove weekly secondary time
 * @param {Number} index
 */
WeeklyCtrl.prototype.removeWeeklyTime = function (dayIndex, timeIndex) {
  this.workingHoursRef[dayIndex].times.splice(timeIndex, 1);
  this.isSecondTimeWeekly = this.checkSecondTimeWeekly();
};

module.exports = WeeklyCtrl;
