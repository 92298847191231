'use strict';

// TODO translations

var messageModule = angular.module('t2g.message', [
    'gettext',
    'ui.router',
    require('t2g.common.services.notificationService').name,
    require('t2g.common.services.modalService').name
]);

module.exports = messageModule;

messageModule.controller('MessageBoxCtrl', require('./MessageBoxCtrl'));
messageModule.controller('MessageMobileCtrl', require('./MessageMobileCtrl'));
messageModule.controller('MessageCtrl', require('./MessageCtrl'));

messageModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.messages', {
            url: '/message/:box/?:page',
            title: 'Messages',
            templateProvider: function($templateRequest, MobileDetectService) {
                if (MobileDetectService.isSM) {
                    return $templateRequest('https://cdn.termin2go.com/app/app/modules/message/MessageBoxMobileView.html?v=3.84.1');
                }

                return $templateRequest('https://cdn.termin2go.com/app/app/modules/message/MessageBoxView.html?v=3.84.1');
            },
            controller: 'MessageBoxCtrl as MessageBoxCtrl',
            resolve: {
                messagesResolve: function($stateParams, Message) {
                    const page = $stateParams.page || 0;
                    return Message.get($stateParams.box, page);
                }
            }
        })
        .state('private.main.messages.mail', {
            url: ':messageId',
            title: 'Messages',
            controller: 'MessageCtrl as MessageCtrl',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/message/MailView.html?v=3.84.1',
            resolve: {
                messageResolve: function($stateParams, Message) {
                    return Message
                      .getOne($stateParams.messageId)
                      .then(function(m){
                          if (m.text) {
                              m.text = m.text.replace(new RegExp(/<title>(.*)<\/title>/), '');
                          }

                          return m;
                      });
                },
                officeLabelResolve: function (OfficeDA, IndustrySpecific) {
                    let officeResolve;
                    return OfficeDA
                        .get()
                        .then((response) => {
                            officeResolve = response;

                            return IndustrySpecific.getLabels();
                        })
                        .then((industrySpecificResolve) => {
                            return {
                                industrySpecificResolve,
                                officeResolve
                            };
                        });
                },
            }
        })
        .state('private.main.messages.sms', {
            url: ':messageId',
            title: 'SMS Messages',
            controller: 'MessageCtrl as MessageCtrl',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/message/SMSView.html?v=3.84.1',
            resolve: {
                messageResolve: function($stateParams, Message) {
                    return Message.getOne($stateParams.messageId);
                }
            }
        })
        .state('private.main.messages-mobile', {
            url: '/message-mobile/:box',
            title: 'Messages',
            controller: 'MessageMobileCtrl as MessageMobileCtrl',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/message/MailMobileView.html?v=3.84.1',
        })
        .state('private.main.messages-mobile.mail', {
            url: '/:messageId',
            title: 'Messages',
            controller: 'MessageCtrl as MessageCtrl',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/message/MailView.html?v=3.84.1',
            resolve: {
                messageResolve: function($stateParams, Message) {
                    return Message
                        .getOne($stateParams.messageId)
                        .then(function(m){
                            if (m.text) {
                                m.text = m.text.replace(new RegExp(/<title>(.*)<\/title>/), '');
                            }
                            return m;
                        });
                },
                officeLabelResolve: function (OfficeDA, IndustrySpecific) {
                    let officeResolve;
                    return OfficeDA
                        .get()
                        .then((response) => {
                            officeResolve = response;

                            return IndustrySpecific.getLabels();
                        })
                        .then((industrySpecificResolve) => {
                            return {
                                industrySpecificResolve,
                                officeResolve
                            };
                        });
                },
            }
        })
        .state('private.main.messages-mobile.sms', {
            url: 'x/:messageId',
            title: 'SMS Messages',
            controller: 'MessageCtrl as MessageCtrl',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/message/SMSView.html?v=3.84.1',
            resolve: {
                messageResolve: function($stateParams, Message) {
                    return Message.getOne($stateParams.messageId);
                }
            }
        });
});
