var trashModule = angular.module('t2g.trash', [
  'gettext',
  'ui.router',
  require('t2g.common.services.modalService').name,
  require('t2g.common.services.notificationService').name
]);

module.exports = trashModule;

trashModule.controller('TrashListCtrl', require('./TrashListCtrl'));

trashModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.trash', {
      url: '/trash',
      title: 'Trash',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/trash/TrashListView.html?v=3.84.1',
      controller: 'TrashListCtrl as TrashListCtrl',
      resolve: {
        items(TrashDA) {
          return TrashDA.list();
        }
      }
    });
});
