'use strict';

var angularModule = angular.module('t2g.common.data-access.calendar', []);

module.exports = angularModule;

angularModule.factory('CalendarResource', function ($resource, settings) {
  var resourceUrl = settings.apiBase + '/api/v2/calendar/:subResource';

  var resource = $resource(resourceUrl, {}, {
    get: { method: 'get' },
    checkCollision: { method: 'get', params: { subResource: 'collision' } }
  });

  return resource;
});

angularModule.factory('CalendarDA', function ($rootScope,
                                              $q,
                                              CalendarResource,
                                              moment) {

  var currentCalendarReference = null;

  return {
    get: get,
    removeAppointment,
    editAppointment,
    insertAppointment,
    checkCollision
  };


  /**
   * add an appointment to calendar
   * @param appointment
   */
  function insertAppointment(appointments) {
    _.forEach(appointments, function (appointment) {
      if (currentCalendarReference) {
        _.forEach(currentCalendarReference.dates, function (date) {
          if (moment(date.date).isBetween(moment(appointment.start), moment(appointment.end), 'day', '[]')) {
            _.forEach(date.resources, function (resource) {
              if (resource._id === appointment.object._id || resource._id === appointment.room) {
                if (_.findIndex(resource.appointments, { _id: appointment._id }) === -1) {
                  resource.appointments.push(appointment);
                }

                return false;
              }
            });
          }
        });
      }
    });

    return $q.when(appointments);
  }

  function checkCollision(appointment, resource, room, start, end) {
    var params = {
      appointment: appointment,
      start: start.toISOString(),
      end: end.toISOString(),
      resource: resource
    };

    if (room) {
      params.room = room;
    }

    return CalendarResource
      .checkCollision(params)
      .$promise
      .then(function (response) {
        return response.result;
      });
  }


  /**
   * edit an appointment in calendar
   * @param appointment
   */
  function editAppointment(appointments) {
    appointments.forEach((appointment) => {
      removeAppointment(appointment._id);
    });

    insertAppointment(appointments);
    $rootScope.$emit('t2g:calendar:changed');

    return appointments;
  }


  /**
   * remove an appointment from calendar
   * @param appointmentId
   */
  function removeAppointment(appointmentId) {
    if (currentCalendarReference) {
      currentCalendarReference.dates.forEach((date) => {
        date.resources.forEach((resource) => {
          resource.appointments = resource.appointments.filter(appointment => appointment._id !== appointmentId);
        });
      });
    }
  }

  function get(start, end, resource, service) {
    var params = {
      start,
      end,
      resource,
      service
    };

    return CalendarResource
      .get(params)
      .$promise
      .then(function (response) {
        currentCalendarReference = response;
        return currentCalendarReference;
      });
  }
});
