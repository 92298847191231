var ShiftPlanningCtrl = function ($state,
                                  $scope,
                                  $stateParams,
                                  employees,
                                  UserSession,
                                  gettextCatalog,
                                  moment) {

  this.employees = employees;
  this._state = $state;
  this._scope = $scope;
  this._moment = moment;
  this.currentEmployeeId = _.get(this._state, 'params.employeeId');
  this.user = UserSession.get();
  this.showDropdown = true;
  this.isBackVisible = false;
  this.isSaveVisible = false;
  this.gettextCatalog = gettextCatalog;

  const editRights2 = _.get(this.user, 'rights.workingHours.edit');

  if (editRights2 !== 'all') {
    this.currentEmployeeId = editRights2;
    this.employeeChanged();
    this.showDropdown = false;
  } else if (!this.currentEmployeeId && this.employees && this.employees.length) {
    this.currentEmployeeId = this.employees[0]._id;
    this.employeeChanged();
  }

  $scope.$watch(() => $state.current.name, () => {
    this.checkRoute();
  });

  this.checkRoute();
};

ShiftPlanningCtrl.prototype.checkRoute = function () {
  const currentMeta = this._state.current.meta;

  if (currentMeta?.showSave) {
    this.isSaveVisible = true;
  } else {
    this.isSaveVisible = false;
  }

  if (currentMeta?.showBack) {
    this.isBackVisible = true;
  } else {
    this.isBackVisible = false;
  }
};

ShiftPlanningCtrl.prototype.back = function () {
  this._state.go('private.main.shiftPlanning.typeSelection', {employeeId: this._state.params.employeeId});
};

ShiftPlanningCtrl.prototype.emitSave = function () {
  this._scope.$broadcast('t2g:shift-planning:save:clicked');
};

ShiftPlanningCtrl.prototype.employeeChanged = function () {
  const date = _.get(this._state, 'params.date', this._moment().startOf('month').format('YYYY-MM-DD'));
  const currentStateName = this._state.current.name;
  const nextState = currentStateName === 'private.main.shiftPlanning' ? 'private.main.shiftPlanning.typeSelection' : currentStateName;

  this._state.go(nextState, {
    employeeId: this.currentEmployeeId,
    date: date,
  });
};

ShiftPlanningCtrl.prototype.runTour = function (standalone) {
  if (typeof introJs === 'undefined') return;

  const intro = introJs();
  let steps = [
    {
      intro: this.gettextCatalog.getString('Setup your opening hours on this site (Master data > Opening Hours). Beware: For calculation of bookable time slots the system uses the resources availability and not the opening hours.')
    },
    {
      element: document.querySelectorAll('.checkbox-open-closed')[0],
      intro: this.gettextCatalog.getString('Activate the checkbox if your business is open on this day of week.'),
      position: 'bottom'
    },
    {
      element: document.querySelectorAll('.button-add-afternoon-times')[0],
      intro: this.gettextCatalog.getString('If you have closed during lunch time add opening hours for the afternoon with a click on the plus icon.'),
      position: 'bottom'
    },
    {
      element: document.querySelectorAll('.button-remove-afternoon-times  ')[0],
      intro: this.gettextCatalog.getString('Click on the minus icon if you want to remove the additional afternoon opening hours.'),
      position: 'bottom'
    }
  ];

  if (standalone) {
    console.log('last step without redirect');
  }

  intro.setOptions({
    steps
  });

  intro.start();
};

module.exports = ShiftPlanningCtrl;
