'use strict';

var ServiceSelectionModalCtrl = function (office, employee, gettextCatalog, ResourceDA, Notification, modalInstance) {
    this.office = office;
    this._modalInstance = modalInstance;
    this._ResourceDA = ResourceDA;
    this._Notification = Notification;
    this.employee = employee;
    this.gettextCatalog = gettextCatalog;
    this.services = [];
    this.submitted = false;
    this.selectedService = null;
    this.existingWorkingHour = null;
    this.existingWorkingHourIsService = false;
    this.existingWorkingHourIsServiceGroup = false;

    this.office.serviceGroups.forEach((serviceGroup) => {
        this.services.push({
            type: 'group',
            name: serviceGroup.name,
            label: serviceGroup.name,
            _id: serviceGroup._id
        });

        serviceGroup.services.forEach((service) => {
            this.services.push({
                type: 'service',
                name: service.name,
                label: `-- ${service.name}`,
                _id: service._id
            });
        });
    });
};

ServiceSelectionModalCtrl.prototype.save = function () {
    this.existingWorkingHour = null;
    this.existingWorkingHourIsService = false;
    this.existingWorkingHourIsServiceGroup = false;
    this.submitted = true;

    // check if employee already have a working hour settings for this service/-group
    if (this.employee.serviceWorkingHours) {
        this.existingWorkingHour = this.employee.serviceWorkingHours.find((serviceWorkingHour) => {
            return serviceWorkingHour.serviceId === this.selectedService._id;
        });
    }

    if (!this.existingWorkingHour) {
        try {
            this._ResourceDA.addServiceWorkingHour({
                resourceId: this.employee._id,
                serviceType: this.selectedService.type,
                serviceId: this.selectedService._id,
                hours: this.employee.workingHours
            }).then(() => {
                this._modalInstance.close('save');
            });
        } catch (error) {
            this._Notification.addErrorMessage(this.gettextCatalog.getString('An Error occured!'));
        }
    } else {
        if (this.existingWorkingHour.serviceType === 'service') {
            this.existingWorkingHourIsService = true;
        } else if (this.existingWorkingHour.serviceType === 'group') {
            this.existingWorkingHourIsServiceGroup = true;
        }
    }
};

ServiceSelectionModalCtrl.prototype.abort = function () {
    this._modalInstance.close('abort');
};

module.exports = ServiceSelectionModalCtrl;
