var OverviewCtrl = function ($scope, $state, employee, services, moment, gettextCatalog, ShiftPlanningDA, ModalService) {
    this._state = $state;
    this._moment = moment;
    this._ModalService = ModalService;
    this._ShiftPlanningDA = ShiftPlanningDA;
    this.employee = employee;
    this.services = services;
    this.gettextCatalog = gettextCatalog;
};

OverviewCtrl.prototype.getServiceName = function(serviceId) {
    return this.services[serviceId].label;
};

OverviewCtrl.prototype.getServiceType = function(serviceType) {
    return {
        service: this.gettextCatalog.getString('Service'),
        group: this.gettextCatalog.getString('Service Group')
    }[serviceType];
};

OverviewCtrl.prototype.openServiceModal = function() {
    this._ModalService.openShitPlanningServiceModal(this._state.params.employeeId, (result) => {
        if (result === 'save') {
            this._state.reload();
        }
    });
};

OverviewCtrl.prototype.goToWeekly = function(serviceId) {
    this._state.go('private.main.shiftPlanning.weekly', {
        employeeId: this._state.params.employeeId,
        serviceId
    });
};

OverviewCtrl.prototype.goToDaily = function(serviceId) {
    const date = _.get(this._state, 'params.date', this._moment().startOf('month').format('YYYY-MM-DD'));

    this._state.go('private.main.shiftPlanning.daily', {
        employeeId: this._state.params.employeeId,
        date,
        serviceId
    });
};

OverviewCtrl.prototype.deleteServiceWorkingHour = function (serviceId) {
    this._ShiftPlanningDA
        .deleteServiceWorkingHours(this._state.params.employeeId, serviceId)
        .then(() => {
            this._state.reload();
        })
        .catch((error) => {
            console.log('error', error);
        });
};

module.exports = OverviewCtrl;
