'use strict';

var shiftPlanningModule = angular.module('t2g.shiftPlanning', [
    'gettext',
    'ui.router',
    require('t2g.common.services.modalServiceNew').name,
    require('t2g.common.data-access.resource').name,
    require('t2g.common.data-access.shiftPlanning').name,
    require('t2g.common.services.3rdParty').name,
    require('t2g.common.services.notificationService').name
]);

module.exports = shiftPlanningModule;

shiftPlanningModule.controller('DailyCtrl', require('./DailyCtrl'));
shiftPlanningModule.controller('OverviewCtrl', require('./OverviewCtrl'));
shiftPlanningModule.controller('ShiftPlanningCtrl', require('./ShiftPlanningCtrl'));
shiftPlanningModule.controller('WeeklyCtrl', require('./WeeklyCtrl'));
shiftPlanningModule.controller('ServiceSelectionModalCtrl', require('./ServiceSelectionModalCtrl'));
shiftPlanningModule.factory('shiftClipboard', require('./shiftClipboardService'));
shiftPlanningModule.factory('workingHoursGenerator', require('./workingHoursGeneratorService'));

function serviceResolve(OfficeDA) {
    const serviceMap = {};
    return OfficeDA
        .get()
        .then((office) => {
            office.serviceGroups.forEach((serviceGroup) => {
                serviceMap[serviceGroup._id] = {
                    label: serviceGroup.name,
                    type: 'group'
                };

                serviceGroup.services.forEach((service) => {
                    serviceMap[service._id] = {
                        label: service.name,
                        type: 'service'
                    };
                });
            });

            return serviceMap;
        });
}

shiftPlanningModule.config(function ($stateProvider) {
    $stateProvider
        .state('private.main.shiftPlanning', {
            url: '/shift-planning',
            title: 'Working Hours',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/shiftPlanning/ShiftPlanningView.html?v=3.84.1',
            controller: 'ShiftPlanningCtrl as ShiftPlanningCtrl',
            resolve: {
                employees: function (ResourceDA) {
                    return ResourceDA.list()
                        .then(function (response) {
                            return response.resources;
                        });
                }
            }
        })
        .state('private.main.shiftPlanning.typeSelection', {
            url: '/:employeeId',
            title: 'Working Hours',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/shiftPlanning/OverviewView.html?v=3.84.1',
            controller: 'OverviewCtrl as OverviewCtrl',
            resolve: {
                employee: function ($stateParams,
                                    ResourceDA) {
                    return ResourceDA.get($stateParams.employeeId).then((response) => response.resource);
                },
                services: serviceResolve
            }
        })
        .state('private.main.shiftPlanning.weekly', {
            url: '/:employeeId/weekly/?:serviceId',
            title: 'Working Hours',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/shiftPlanning/WeeklyView.html?v=3.84.1',
            controller: 'WeeklyCtrl as WeeklyCtrl',
            meta: {
                showSave: true,
                showBack: true,
            },
            resolve: {
                employee: function ($stateParams,
                                    ResourceDA) {
                    return ResourceDA.get($stateParams.employeeId).then((response) => response.resource);
                },
                office: function (OfficeDA) {
                    return OfficeDA.get();
                },
                services: serviceResolve
            }
        })
        .state('private.main.shiftPlanning.daily', {
            url: '/:employeeId/daily/:date/?:serviceId',
            title: 'Working Hours',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/shiftPlanning/DailyView.html?v=3.84.1',
            controller: 'DailyCtrl as DailyCtrl',
            meta: {
                showSave: true,
                showBack: true,
            },
            resolve: {
                shiftPlanning: function ($q,
                                         $stateParams,
                                         CountryConfigResource,
                                         ResourceDA,
                                         ShiftPlanningDA,
                                         OfficeDA,
                                         workingHoursGenerator) {
                    const year = $stateParams.date ? moment($stateParams.date).year() : moment().year();

                    return OfficeDA
                        .get()
                        .then((office) => {
                            return $q.all([
                                ResourceDA.get($stateParams.employeeId),
                                ShiftPlanningDA.get($stateParams.employeeId, $stateParams.date, $stateParams.serviceId),
                                CountryConfigResource.get({
                                    countryCode: office.address.countryCode,
                                    years: year
                                }).$promise
                            ]).then(function (responses) {
                                var employee = responses[0].resource;
                                var shifts = responses[1];
                                var countryConfig = responses[2].countryConfig;
                                var dates = workingHoursGenerator.get(employee, shifts, countryConfig);

                                return {
                                    employee: employee,
                                    dates: dates
                                };
                            });
                        });
                },
                office: function (OfficeDA) {
                    return OfficeDA.get();
                },
                services: serviceResolve
            }
        });
});