var statisticsModule = angular.module('t2g.statistic', [
  'chart.js',
  'ui.router',
  require('t2g.common.data-access.statistic').name,
  require('t2g.common.services.notificationService').name
]);

module.exports = statisticsModule;

statisticsModule.controller('StatisticSalesCtrl', require('./StatisticSalesCtrl'));
statisticsModule.controller('StatisticTestsCtrl', require('./StatisticTestsCtrl'));
statisticsModule.controller('StatisticSalesPerEmployeeCtrl', require('./StatisticSalesPerEmployeeCtrl'));
statisticsModule.controller('StatisticSalesPerServiceCtrl', require('./StatisticSalesPerServiceCtrl'));

statisticsModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.statistic-sales', {
      url: '/statistic/sales?start&end&resolution',
      title: 'Statistics',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/statistic/StatisticSalesView.html?v=3.84.1',
      controller: 'StatisticSalesCtrl as StatisticSalesCtrl',
      resolve: {
        salesResponse: function ($stateParams, StatisticDA) {
          var today = moment();
          var start = $stateParams.start || today.startOf('month').format('YYYY-MM-DD');
          var end = $stateParams.end || today.endOf('month').format('YYYY-MM-DD');
          var resolution = 'day';

          return StatisticDA.getSales(start, end, resolution);
        }
      }
    })
    .state('private.main.statistic-sales-per-employee', {
      url: '/statistic/salesPerEmployee?start&end',
      title: 'Statistics',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/statistic/StatisticSalesPerEmployeeView.html?v=3.84.1',
      controller: 'StatisticSalesPerEmployeeCtrl as StatisticSalesPerEmployeeCtrl',
      resolve: {
        salesResponse: function ($stateParams, StatisticDA) {
          var today = moment();
          var start = $stateParams.start || today.startOf('month').format('YYYY-MM-DD');
          var end = $stateParams.end || today.endOf('month').format('YYYY-MM-DD');

          return StatisticDA
            .getSalesPerEmployee(start, end)
            .then(function (response) {
              var transformedResponse = {
                data: [],
                labels: [],
                overall: response.overall,
                untransformed: response,
                sum: response.sum
              };

              angular.forEach(response.data, function (dataRow) {
                transformedResponse.data.push(dataRow.value);
                transformedResponse.labels.push(dataRow.label);
              });

              return transformedResponse;
            });
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    })
    .state('private.main.statistic-sales-per-service', {
      url: '/statistic/salesPerService?start&end',
      title: 'Statistics',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/statistic/StatisticSalesPerServiceView.html?v=3.84.1',
      controller: 'StatisticSalesPerServiceCtrl as StatisticSalesPerServiceCtrl',
      resolve: {
        salesResponse: function ($stateParams, StatisticDA) {
          var today = moment();
          var start = $stateParams.start || today.startOf('month').format('YYYY-MM-DD');
          var end = $stateParams.end || today.endOf('month').format('YYYY-MM-DD');

          return StatisticDA
            .getSalesPerService(start, end)
            .then(function (response) {
              var transformedResponse = {
                data: [],
                labels: [],
                overall: response.overall,
                untransformed: response,
                sum: response.sum
              };

              angular.forEach(response.data, function (dataRow) {
                transformedResponse.data.push(dataRow.value);
                transformedResponse.labels.push(dataRow.label);
              });

              return transformedResponse;
            });
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    })
    .state('private.main.statistic-tests', {
      url: '/statistic/tests/:testType?date&resolution',
      title: 'Statistics',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/statistic/StatisticTestView.html?v=3.84.1',
      controller: 'StatisticTestsCtrl as StatisticTestsCtrl',
      resolve: {
        stats: function ($stateParams, StatisticDA) {
          const today = moment();
          const date = $stateParams.date || today.format('YYYY-MM-DD');
          const resolution = $stateParams.resolution || 'month';
          const testType = $stateParams.testType || 'quickTest';

          return StatisticDA.getTests(date, resolution, testType);
        },
        office: function (OfficeDA) {
          return OfficeDA.get();
        }
      }
    });
});
