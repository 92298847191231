'use strict';

var angularModule = angular.module('t2g.common.directives', [
    require('./autoExpandDirective').name,
    require('./autoFillFixDirective').name,
    require('./capitalizeDirective').name,
    require('./colorPickerDirective').name,
    require('./confirmButtonDirective').name,
    require('./fab').name,
    require('./fakeClickDirective').name,
    require('./focusDirective').name,
    require('./focusLockDirective').name,
    require('./horizontalFixedDirective').name,
    require('./googlePlacesAutocomplete').name,
    require('./menuDirective').name,
    require('./navigationDirective').name,
    require('./numberInputDirective').name,
    require('./offCanvas').name,
    require('./onEnterDirective').name,
    require('./onResizeDirective').name,
    require('./onScrollDirective').name,
    require('./pwCheckDirective').name,
    require('./priceInputDirective').name,
    require('./radioButtonDirective').name,
    require('./remoteKeyBoardDirective').name,
    require('./stateTitleDirective').name,
    require('./tabLinkDirective').name,
    require('./timePickerDirective').name,
    require('./trackErrosDirective').name,
    require('./toggleLabelDirective').name,
    require('./wavesDirective').name,
    require('./wysiwygDirective').name,
    require('./uniqueInputDirective').name,
    require('./intInputDirective').name,
]);

module.exports = angularModule;
