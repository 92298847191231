'use strict';

var DailyCtrl = function ($scope,
                          $state,
                          $stateParams,
                          shiftPlanning,
                          gettextCatalog,
                          office,
                          services,
                          Notification,
                          moment,
                          ResourceDA,
                          shiftClipboard,
                          ShiftPlanningDA,
                          DatepickerSettings) {

    var self = this;

    this.gettextCatalog = gettextCatalog;
    this._Notification = Notification;
    this._ResourceDA = ResourceDA;
    this._shiftClipboard = shiftClipboard;
    this._ShiftPlanningDA = ShiftPlanningDA;
    this._state = $state;
    this._stateParams = $stateParams;
    this._moment = moment;
    this._services = services;
    this.showAbsence = true;

    this.employee = shiftPlanning.employee;
    this.weekDays = [];
    this.office = office;
    this.dates = shiftPlanning.dates;
    this.isSecondTimeDaily = this.checkSecondTimeDaily();
    this.currentMonth = moment(this._stateParams.date).toDate();
    this.dtpOptions = DatepickerSettings.minMonth;

    this.absenceReasons = [
        {
            key: 'other',
            text: gettextCatalog.getString('Other')
        },
        {
            key: 'vacation',
            text: gettextCatalog.getString('Vacation')
        },
        {
            key: 'ill',
            text: gettextCatalog.getString('Ill')
        },
        {
            key: 'pd',
            text: gettextCatalog.getString('PD')
        }
    ];

    $scope.$watch(function () {
        return self.currentMonth;
    }, function (newMonth, oldMonth) {
        if (newMonth && newMonth.toString() !== oldMonth.toString()) {
            var newDate = moment(newMonth).startOf('month').format('YYYY-MM-DD');

            self._state.go('private.main.shiftPlanning.daily', {
                employeeId: self.employee._id,
                date: newDate
            }, {notify: false});
        }
    });

    $scope.$on('t2g:shift-planning:save:clicked', () => {
      this.saveDaily();
    });

    if (this._stateParams.serviceId) {
        this.showAbsence = false;
    }
};


/**
 * check if a second time was added in the weekly planning
 * @returns {boolean}
 */
DailyCtrl.prototype.checkSecondTimeWeekly = function () {
    var secondTimeIndex = _.findIndex(this.employee.workingHours, function (workingHours) {
        return workingHours.times.length > 1;
    });

    return secondTimeIndex > -1 ? true : false;
};


/**
 * check if a second time was added in the daily planning
 * @returns {boolean}
 */
DailyCtrl.prototype.checkSecondTimeDaily = function () {
    var secondTimeIndex = _.findIndex(this.dates, function (date) {
        var times = date.times;

        if (times) {
            return date.times.length > 1;
        } else {
            return false;
        }
    });

    return secondTimeIndex > -1 ? true : false;
};


/**
 * month change handler
 * @param {String} month
 */
DailyCtrl.prototype.changeMonth = function (month) {
    var monthMoment = this._moment(this.currentMonth).startOf('month');
    monthMoment.add(month, 'month');
    this.currentMonth = monthMoment.toDate();
};


/**
 * save daily working hours
 */
DailyCtrl.prototype.saveDaily = function () {
    var self = this;
    var update = {
        resource: this.employee._id,
        month: this._moment(this.currentMonth).format('YYYY-MM-DD'),
        dates: {}
    };

    if (this._stateParams.serviceId) {
        const selectedService = this._services[this._stateParams.serviceId];
        update.serviceType = selectedService.type;
        update.serviceId = this._stateParams.serviceId;
    }

    _.forEach(this.dates, function (date) {
        var dateKey;

        if (date.type !== 'default') {
            dateKey = self._moment(date.date).format('YYYY-MM-DD');
            update.dates[dateKey] = date;
        }
    });


    this._ShiftPlanningDA
        .update(update)
        .then(
            function () {
                self._Notification.addSuccessMessage(self.gettextCatalog.getString('Working hours saved'));
            },
            function (error) {
                self._Notification.addErrorMessage(self.gettextCatalog.getString('Cant save working hours (Error: {{error}})', {error: error}));
            }
        );
};


/**
 * copy a shift
 * @param {Number} index
 */
DailyCtrl.prototype.copy = function (index) {
    this._shiftClipboard.copy(this.dates[index]);
};


/**
 * paste clipboard to a date
 * @param {Number} index
 */
DailyCtrl.prototype.paste = function (index) {
    this._shiftClipboard.paste(this.dates[index]);
};


/**
 * handler if type of daily planning changed
 * @param {Number} index
 */
DailyCtrl.prototype.typeChanged = function (index) {
    var date = this.dates[index];
    var type = date.type;

    switch (type) {
        case 'default':
            var dayOfWeek = this._moment(date.date).weekday();
            date.times = _.cloneDeep(this.employee.workingHours[dayOfWeek].times);

            delete date.absence;
            delete date.reason;
            break;
        case 'custom':
            delete date.absence;
            delete date.reason;
            break;
        case 'absence':
            date.available = false;
            date.absence = true;
            date.reason = 'other';
            break;

    }
};


/**
 * add daily secondary time
 * @param {Number} index
 */
DailyCtrl.prototype.addDailyTime = function (index) {
    var firstEndTime = this.dates[index].times[0].end;

    this.dates[index].times.push({
        start: ((firstEndTime + 60) > 1440) ? 1440 : firstEndTime + 60,
        end: ((firstEndTime + 240) > 1440) ? 1440 : firstEndTime + 240
    });

    this.isSecondTimeDaily = true;
};


/**
 * remove daily secondary time
 * @param {Number} index
 */
DailyCtrl.prototype.removeDailyTime = function (index) {
    this.dates[index].times.splice(1, 1);
    this.isSecondTimeDaily = this.checkSecondTimeDaily();
};


DailyCtrl.prototype.getColspan = function () {
    var colspan = 5;

    if (!this.isSecondTimeDaily) {
        colspan = 3;
    }

    return colspan;
};

module.exports = DailyCtrl;
