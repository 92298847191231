'use strict';

var angularModule = angular.module('t2g.common.data-access.shiftPlanning', []);

module.exports = angularModule;

angularModule.factory('ShiftPlanningResource', function ($resource, settings) {
        var resourceUrl = settings.apiBase + '/api/v2/shiftPlanning/:resourceId/:subResource1';

        var resource = $resource(resourceUrl, {}, {
            get: {method: 'get'},
            update: {method: 'put', params: {resourceId: '@resource'}},
            deleteServiceWorkingHours: {method: 'delete'}
        });

        return resource;
    }
);

angularModule.factory('ShiftPlanningDA', function (ShiftPlanningResource,
                                                   LoadingIndicatorService) {

    return {
        get: get,
        update: update,
        deleteServiceWorkingHours
    };

    function deleteServiceWorkingHours(resourceId, serviceId) {
        LoadingIndicatorService.show();
        return ShiftPlanningResource
            .deleteServiceWorkingHours({resourceId, subResource1: serviceId})
            .$promise
            .then(function (response) {
                LoadingIndicatorService.hide();
                return response;
            });
    }

    function get(resourceId, date, serviceId) {
        LoadingIndicatorService.show();
        return ShiftPlanningResource
            .get({resourceId, date, serviceId})
            .$promise
            .then(function (response) {
                LoadingIndicatorService.hide();
                return response;
            });
    }

    function update(shift) {
        LoadingIndicatorService.show();
        return ShiftPlanningResource
            .update(shift)
            .$promise
            .then(function (response) {
                LoadingIndicatorService.hide();
                return response;
            });
    }

});
